import React from 'react'

import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import { Separator } from 'components/Separator'
import { ModalBody } from 'theme/layout/index.styles'
import { TextStyled } from './index.resolvers'
import { handleDisabledButton, redirectToWithoutAlias } from 'utils/resolvers'
import { ClientAccountSchema as validationSchema } from './index.validations'
import {
   Box,
   Row,
   Col,
   TextFormat,
   Button,
   TextField,
   Toast,
} from 'sensedia-ui-kit'

export const ClientAccount = () => {
   const [t] = useTranslation()
   const { protocol, host } = window.location

   const initialValues: any = {
      clientAccount: '',
   }

   const formik = useFormik({
      validateOnMount: true,
      validationSchema,
      initialValues,
      onSubmit: ({ clientAccount }, { setSubmitting }) => {
         const putAliasInURL = redirectToWithoutAlias(clientAccount)

         if (putAliasInURL === 'error') {
            Toast.error(`${clientAccount} is not valid`)
            setSubmitting(false)
         }
      },
   })

   return (
      <ModalBody>
         <form onSubmit={formik.handleSubmit}>
            <Row noGutter>
               <Col>
                  <TextFormat
                     size={14}
                     color="grayMedium"
                     align="left"
                     weight="bold"
                     data-testid="component-subhead"
                  >
                     {t('client-account-title')}
                  </TextFormat>
               </Col>

               <Col mt={30}>
                  <TextFormat
                     size={12}
                     color="grayMedium"
                     align="center"
                     weight="medium"
                     data-testid="component-url-example"
                     style={TextStyled}
                  >
                     {t('client-account-subhead-example', { protocol, host })}
                  </TextFormat>
               </Col>
            </Row>

            <Row noGutter>
               <Col mt={40} mb={40} style={{ position: 'relative' }}>
                  <Separator size={400} />
               </Col>
            </Row>

            <Row noGutter>
               <Col>
                  <TextFormat
                     size={14}
                     color="grayMedium"
                     align="left"
                     weight="bold"
                     data-testid="component-subhead"
                  >
                     {t('client-account-subhead')}
                  </TextFormat>
               </Col>

               <Col mt={30} mb={50}>
                  <TextField
                     name="clientAccount"
                     title={t('default-fill-out')}
                     data-testid="input-clientAccount"
                     label={t('client-account-field-account')}
                     value={formik.values.clientAccount}
                     onChange={formik.handleChange}
                     error={formik.errors.clientAccount as string}
                     maxLength={100}
                     countChar={false}
                     autoComplete="off"
                     required
                  />
               </Col>

               <Col mb={10}>
                  <Box justify="center">
                     <Button
                        variant="contained"
                        data-testid="btn-clientAccount"
                        isLoading={formik.isSubmitting}
                        disabled={handleDisabledButton(formik.errors)}
                        style={{ width: '100%' }}
                     >
                        {t('client-account-button-access-account')}
                     </Button>
                  </Box>
               </Col>
            </Row>
         </form>
      </ModalBody>
   )
}
