import i18n from 'locale/i18n'
import * as yup from 'yup'

const ClientAccountSchema = yup.object().shape({
   clientAccount: yup
      .string()
      .max(63, i18n.t('error-validation-max-char', { param: 63 }))
      .required(i18n.t('error-validation-field-required'))
      .test({
         message: i18n.t('error-validation-blanks'),
         test: (value) => !!value && !value.match(/\s/),
      }),
})

export { ClientAccountSchema }
